.detail-index-top-right {
  position: relative;
  .right-top{
    min-height: 3.2rem;
  }
  .activity-detail-top-title {
    font-size: (22rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    line-height: (34rem / 100);
    color: #0f0f0f;
    margin-bottom: (15rem / 100);
    width: (496rem / 100);
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
    // overflow: hidden;
  }

  .activity-detail-top-status {
    width: (100rem / 100);
    height: (26rem / 100);
    margin-bottom: (12rem / 100);

    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    &-finished {
      width: 1rem;
      height: 0.26rem;
      line-height: 0.26rem;
      background: url("https://image.bookgo.com.cn/webculture/jm/activity/detail/activity-status-finished.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #fff;
    }
    &-applying {
      width: 1rem;
      height: 0.26rem;
      line-height: 0.26rem;
      background: url("https://image.bookgo.com.cn/webculture/jm/activity/detail/activity-status-doing.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #fff;
    }
    &-applyingend {
      width: 1rem;
      height: 0.26rem;
      line-height: 0.26rem;
      background: url("https://image.bookgo.com.cn/webculture/jm/activity/detail/activity-status-notbegin.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #761f1e;
    }
    &-signing {
      width: 1rem;
      height: 0.26rem;
      line-height: 0.26rem;
      background: url("https://image.bookgo.com.cn/%20webculture/jm/activity-status-signing.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #fff;
    }
    &-notbegin {
      width: 1rem;
      height: 0.26rem;
      line-height: 0.26rem;
      background: url("https://image.bookgo.com.cn/webculture/jm/activity/detail/activity-status-notbegin.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #761f1e;
    }
  }

  .activity-detail-top-des {
    // margin-bottom: (75rem / 100);

    &-ul {
      font-size: (16rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      line-height: (22rem / 100);

      &-li {
        display: flex;
        align-items: flex-start;
        margin-bottom: (8rem / 100);

        &-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (12rem / 100);
        }

        &-label {
          width: 100%;
          font-weight: 500;
          font-size: (16rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      line-height: (22rem / 100);
          span{
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
          }
        }
      }
    }
  }

  .activity-detail-top-button {
    width: (120rem/ 100);
    height: (40rem / 100);
    background: #761f1e;
    border-radius: (25rem / 100);
    text-align: center;
    line-height: (40rem / 100);
    font-size: (16rem / 100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    // position: absolute;
    // bottom: 0px;
  }
  .pink{
    background: #8797AD;
  }
  .background-gray {
    background: #888888;
  }

  .background-pink {
    background: rgba($color: #761F1E, $alpha: 0.33);
    color: #fff;
  }
  .background-hui{
    background: #888888;
    color: white;
  }
}

.detail-index-bottom-content {
  padding: 0 (16rem / 100) (146rem / 100) 0;

  &-top {
    width: (1200rem / 100);
    height: (100rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_bottom_top_bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 5px 15px 0 rgba(207, 181, 140, 0.8);
    display: flex;
    align-items: center;
    padding-left: (30rem / 100);
    margin-bottom: (28rem / 100);

    &-title {
      width: (140rem / 100);
      height: (31rem / 100);
      text-align: center;
      line-height: (31rem / 100);
      font-size: (20rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_title_bg.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .detail-index-bottom-content-bottom-box {
    margin-bottom: (44rem / 100);
    padding: 0 (30rem / 100);

    &-top {
      display: flex;
      align-items: center;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (16rem / 100);
      margin-bottom: (15rem / 100);

      &-icon {
        width: (9rem / 100);
        height: (16rem / 100);
        margin-right: (7rem / 100);
      }
    }

    &-bottom {
      padding-left: (16rem / 100);
      font-size: (14rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      line-height: (28rem / 100);
    }
  }
}
